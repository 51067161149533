import React from "react";
import { navigate } from "gatsby";
import { useLocation } from '@reach/router';

const ctaAction = (text, location) => {
  const gTag = typeof window !== "undefined" ? window.gtag : null;
  
  if (gTag) {
    window.gtag("event", "click", {
      event_category: "CTA",
      event_label: text,
      event_value: location,
    });
  }
};
const CTA = ({ href, text, className }) => {
  const location = useLocation()?.pathname;
  return (
    <button
      className={className}
      type="button"
      onClick={() => {
        ctaAction(text, location);
        navigate(href);
      } }
    >
      {text}
    </button>
  );
};

export default CTA;
