import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo"
import CTA from "../components/cta";

import BlogFeed from "../components/blog-feed";

import patternHex from "../images/backrounds/pattern-hex-white.jpg";
import Quote from "../components/quote";

const Index = ({location}) => (
  <Layout>
    <Seo
      pathName={location.pathname}
      title="Zendesk Email Templates"
    />
    <section>
      <div
        className="hero"
        style={{
          backgroundImage: `url(${patternHex})`,
        }}
      >
        <div className="hero-title bg-primary-transparent">
          <div className="hero-image">
            <StaticImage
              src="../images/clients/zendesk.png"
              alt="Zendesk Implementation Premier Partner"
              layout="constrained"
              style={{ maxWidth: "200px"}}
            />
          </div>
          <h1 className="text-white">
            Are you tired of sending ugly Zendesk emails? 729 can help!
          </h1>
          <p className="text-white">
            We can agree that Zendesk is a pretty awesome tool, but let’s be
            honest… The email templates that come out of the box are pretty
            hideous. Do they work? Sure. But dropping your logo into these
            monstrosities may actually have a negative impact on your brand and
            reduce trust with your customers. Enter 729…
          </p>
        </div>
      </div>
    </section>
    <section className="bg-dark">
      <div className="container py-6">
        <div className="row d-flex justify-content-center justify-content-md-between">
          <div className="col-md-6 col-xl-5">
            <h5 className="text-white">Increase customer trust with a</h5>
            <h2 className="text-white">
              Responsive & Custom Zendesk Email Template
            </h2>
            <p className="text-white mb-4">
              We can build you a beautiful branded Zendesk email template and
              empower your customer-agent connection with a better Help Center
              email experience.
            </p>
          </div>
          <div className="col-6 col-md-4 col-xl-3 mb-md-n6">
            <StaticImage  
              className="mb-md-n6"
              src="../images/email-templates/Zendesk_Email_Circle_Avatar_iPhone_Mockup.png"
              alt="Zendesk Email Circle Avatar iPhoneMockup"
            />
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container py-6">
        <div className="row">
          <div className="col">
            <h2>Our Process</h2>
            <h3>We Got You</h3>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4">
          <div className="col mb-4">
            <div className="card custom-cards h-100">
              <div className="card-body">
                <svg
                  className="text-dark"
                  height={100}
                  viewBox="0 0 144 144"
                  width={100}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <polyline
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    points="98.41,75.62 121.74,98.94 97.67,123.01 74,99.35 "
                  />
                  <polyline
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    points="45.64,70.98 13.79,39.14 37.86,15.07 69.7,46.91 "
                  />
                  <line
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    x1="27.55"
                    y1="53.49"
                    x2="51.86"
                    y2="29.85"
                  />
                  <rect
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    x="9.49"
                    y="52.74"
                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -30.4783 72.0021)"
                    width="124.36"
                    height="40.1"
                  />
                  <line
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    x1="33.85"
                    y1="82.76"
                    x2="46.69"
                    y2="95.6"
                  />
                  <line
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    x1="45.79"
                    y1="70.83"
                    x2="54.79"
                    y2="79.84"
                  />
                  <line
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    x1="57.72"
                    y1="58.9"
                    x2="70.55"
                    y2="71.73"
                  />
                  <line
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    x1="69.65"
                    y1="46.96"
                    x2="78.66"
                    y2="55.97"
                  />
                  <line
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    x1="81.58"
                    y1="35.03"
                    x2="94.42"
                    y2="47.86"
                  />
                  <polyline
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    points="122.32,98.88 122.32,123.63 97.55,123.63 "
                  />
                </svg>
                <h4>Design</h4>
                <p>
                  We will work closely with you to craft gorgeous Zendesk email
                  templates.
                </p>
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card custom-cards h-100">
              <div className="card-body">
                <svg
                  className="text-dark"
                  height={100}
                  viewBox="0 0 144 144"
                  width={100}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <rect
                      fill="none"
                      height="11.61"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      width="23.22"
                      x="102.96"
                      y="91.35"
                    />
                    <line
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      x1="114.57"
                      x2="114.57"
                      y1="64.26"
                      y2="91.35"
                    />
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      d="M54.59,54.59v69.65H37.17V54.59"
                    />
                    <line
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      x1="21.69"
                      x2="70.07"
                      y1="124.24"
                      y2="124.24"
                    />
                    <path
                      d="M62.32,37.17,126.17,43V54.59H17.82V41l11.61-3.87"
                      fill="none"
                      stroke="currentColor"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                    <line
                      fill="none"
                      stroke="currentColor"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      x1="54.59"
                      x2="37.17"
                      y1="54.59"
                      y2="72"
                    />
                    <line
                      fill="none"
                      stroke="currentColor"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      x1="54.59"
                      x2="37.17"
                      y1="89.41"
                      y2="106.83"
                    />
                    <line
                      fill="none"
                      stroke="currentColor"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      x1="54.59"
                      x2="37.17"
                      y1="89.41"
                      y2="72"
                    />
                    <line
                      fill="none"
                      stroke="currentColor"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      x1="54.59"
                      x2="37.17"
                      y1="124.24"
                      y2="106.83"
                    />
                    <path
                      d="M29.43,54.59V19.76H56.52l5.8,17.41V54.59"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                    <path
                      d="M41,19.76v9.67a7.74,7.74,0,0,0,7.74,7.74H62.32"
                      fill="none"
                      stroke="currentColor"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                  </g>
                </svg>
                <h4>Develop</h4>
                <p>
                  Our expert developers will build you responsive and functional
                  emails.
                </p>
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card custom-cards h-100">
              <div className="card-body">
                <svg
                  className="text-dark"
                  height={100}
                  viewBox="0 0 144 144"
                  width={100}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    d="M45.95,107.33c0-4.52,3.64-8.14,8.17-8.13c4.5,0.01,8.11,3.66,8.1,8.2c-0.01,4.46-3.68,8.09-8.17,8.08  C49.56,115.46,45.94,111.82,45.95,107.33z"
                  />
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    d="M63.85,87.81c0-3.62,2.92-6.52,6.54-6.51c3.6,0.01,6.5,2.94,6.49,6.57c-0.01,3.57-2.95,6.48-6.55,6.47  C66.74,94.33,63.84,91.41,63.85,87.81z"
                  />
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    d="M78.59,109.16c0-2.96,2.38-5.32,5.34-5.32c2.94,0,5.31,2.4,5.3,5.36c-0.01,2.92-2.41,5.29-5.35,5.28  C80.96,114.48,78.59,112.1,78.59,109.16z"
                  />
                  <rect
                    x="40.69"
                    y="21.5"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    width="56.53"
                    height="13.77"
                  />
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    d="M59.52,42.1v23.42c-3.83,4.5-31.97,43.7-31.97,49.01v0.82c0,5.31,4.3,9.61,9.61,9.61h66.12  c5.31,0,9.61-4.3,9.61-9.61v-0.82c-1.35-5.55-33.1-48.78-33.1-48.78V41.88"
                  />
                </svg>
                <h4>Test</h4>
                <p>
                  From desktop to mobile, the 729 testing team will ensure
                  perfection.
                </p>
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card custom-cards h-100">
              <div className="card-body">
                <svg
                  className="text-dark"
                  height={100}
                  viewBox="0 0 144 144"
                  width={100}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    d="M31.34,82.55l30.13,30.71c0,0,72.03-34.15,68.59-98.72C130.06,14.53,70.66,7.07,31.34,82.55z"
                  />
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    d="M67.82,64.42c0.01-6.24,5.02-11.23,11.27-11.22c6.21,0.01,11.19,5.06,11.18,11.32  C90.26,70.67,85.2,75.68,79,75.66C72.81,75.64,67.82,70.62,67.82,64.42z"
                  />
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    d="M42.6,115.91c-3.14,3.49-21.47,8.13-21.47,8.13s2.71-18.72,5.86-22.22c3.1-3.44,9.15-3.08,13.46,0.8  C44.76,106.5,45.72,112.43,42.6,115.91z"
                  />
                  <line
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="3"
                    x1="89.6"
                    y1="23.43"
                    x2="121.16"
                    y2="55.28"
                  />
                  <g>
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      d="M69.15,35.24c-14.63-1.61-29.79,3.62-40.35,15.41l14.18,12.63C51.41,51.22,60.34,42.11,69.15,35.24z"
                    />
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      d="M83.57,99.43l15.73,14.01c10.02-11.32,13.61-26.11,11.06-39.91C101.97,84.33,92.18,92.99,83.57,99.43z"
                    />
                  </g>
                </svg>
                <h4>Launch</h4>
                <p>
                  When you’re ready, we’ll deploy so you can enjoy the fruits of our labor.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="bg-primary-alternative">
      <div className="container py-6">
        <div className="row d-flex justify-content-between">
          <div className="col-lg-5">
            <h2 className="text-white">
              Elevate Customer Support Experience
            </h2>
            <h5 className="text-white">And look good doing it!</h5>
            <p className="text-white mb-4">
              A great customer experience is critical especially when it comes to providing support. Don’t let those ugly Zendesk email templates throw negative shade on your CX team’s capabilities.
            </p>
          </div>
          <div className="col-lg-6 col-lg-7">
            <StaticImage
              src="../images/email-templates/Zendesk_Email_Illustration_iPhone_Macbook_Mockup.png"
              alt="Zendesk Email Illustration Avatar iPhone Macbook Mockup"
            />
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container py-6">
        <div className="row d-flex justify-content-center justify-content-lg-between">
          <div className="col-lg-5">
            <h2>
              Offer Better Engagement
            </h2>
            <h3>Make emails stand out</h3>

            <div className="card custom-cards card-border mb-3">
              <div className="card-body">
                <h4>Your Brand Identity</h4>
                <p>Ditch the default templates and start sending emails that are aligned with your brand standards</p>
              </div>
            </div>
            <div className="card custom-cards card-border mb-3">
              <div className="card-body">
                <h4>Call-to-action</h4>
                <p>Effortlessly encourage customers to take the next step with a clear call-to-action in your emails.</p>
              </div>
            </div>
            <div className="card custom-cards card-border mb-4">
              <div className="card-body">
                <h4>Social Media</h4>
                <p>GAllow customers to connect with you on social so they can share their awesome customer experience.</p>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-7 col-xl-6">
            <StaticImage
              src="../images/email-templates/Zendesk_Email_Double_iPhone_Mockup.png"
              alt="Zendesk Email Double iPhone Mockup"
            />
          </div>
        </div>
      </div>
    </section>
    <section className="bg-dark">
      <div className="container py-6">
        <div className="row">
          <div className="col-lg-4">
            <StaticImage
              src="../images/partners/zendesk-implementation-premier-partner-badge.png"
              alt="Zendesk Implementation Premier Partner"
              width={400}
              layout="constrained"
            />
          </div>
          <div className="col-lg-8">
            <h2 className="text-white">
              Can You Afford to Keep Wasting Time Zendesk?
            </h2>
            <p className="text-white">
              We can help you make the most of Zendesk.  Give us a call and find out how today.
            </p>
            <CTA
              href="/contact-us/"
              className="btn btn-secondary-light"
              text="SET UP A FREE CALL"
            />
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container pt-6">
        <div className="row">
          <div className="col">
            <Quote
              name="Deeanne Akerson"
              company="Kindred Bravely"
              quote="After one call with 729 Solutions, they understood our unique workflow and what we were trying to do. I can't recommend them highly enough - they’ve saved years of stress and frustration from my life, and I'm so relieved to know that whenever we have issues come up, they'll be here! Every eCommerce business needs them on their team!"
            />
          </div>
        </div>
      </div>
    </section>
    <BlogFeed containerClassname="py-6" />
  </Layout>
);

export default Index;
